<template>
    <div class="helpful" v-if="hasValidEntries" >
        <h3>Hilfreiche Links</h3>
        <ul class="helpful-links">
            <HelpfulLinks v-for="(item, index) in hilfreicheLinksArray" :key="item.id" :link="item"/>
        </ul>
    </div>
</template>

<script>
    import { getFieldValues } from '@/utils/helpers';

    export default {
        name: "HilfreicheLinksBlock",
        components: {
            HelpfulLinks: () => import('./HelpfulLinks.vue'),
        },
            props: {
                hilfreicheLinksArray: {
                    type: Array,
                    required: true
                },
            },
            computed: {
                hasValidEntries(){
                   if(this.hilfreicheLinksArray !== null && this.hilfreicheLinksArray.length > 0){
                    for(let i = 0; i<this.hilfreicheLinksArray.length; i++){
                        var value = getFieldValues(this.hilfreicheLinksArray[i],'link-url');
                        if(value !== null){
                            return true;
                        }
                    }
                }
                return false;
            },
        },
        methods:{
           getFieldValues, 
       }
   }
</script>